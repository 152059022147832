import * as Sentry from "@sentry/react";
import React from "react";
import { useClientMessagePort } from "./util/messagePort";
import { UiBridgeClientMessage, UiBridgeConfig, UiBridgeHostMessage } from "./clientConfig";

export type UiBridgeClientMessageCallback<TConfig extends UiBridgeConfig> = (
  message: UiBridgeClientMessage<TConfig>,
) => void;

export type UiBridgeClientSendMessageFn<TConfig extends UiBridgeConfig> = (
  action: UiBridgeHostMessage<TConfig>,
) => Promise<boolean>;

export type UiBridgeClientProviderProps<TConfig extends UiBridgeConfig> = React.PropsWithChildren<{
  config: TConfig;
  allowedHostOrigins?: Array<string>;
  onMessage: UiBridgeClientMessageCallback<TConfig>;
  onSendMessageFnChange: (dispatchFn: UiBridgeClientSendMessageFn<TConfig> | null) => void;
}>;

export const UiBridgeClient = <TConfig extends UiBridgeConfig>(props: UiBridgeClientProviderProps<TConfig>) => {
  const { config, allowedHostOrigins = [], onMessage, onSendMessageFnChange } = props;

  const messagePort = useClientMessagePort(allowedHostOrigins);

  React.useEffect(() => {
    if (!messagePort) {
      onSendMessageFnChange(null);
      return;
    }

    const portMessageHandler = async (event: MessageEvent) => {
      const parsedMessage = await config.clientMessageSchema.safeParseAsync(event.data);

      if (parsedMessage.success) {
        onMessage(parsedMessage.data);
      } else {
        // eslint-disable-next-line no-console
        console.error({ error: parsedMessage.error.format(), message: event.data });
        Sentry.captureMessage("Could not parse message from ui-bridge host", {
          level: "error",
          extra: parsedMessage.error.format(),
        });
      }
    };

    onSendMessageFnChange(async (message) => {
      messagePort.postMessage(message);
      return true;
    });

    messagePort.addEventListener("message", portMessageHandler);
    messagePort.start();

    return () => {
      messagePort.removeEventListener("message", portMessageHandler);
    };
  }, [config.clientMessageSchema, messagePort, onSendMessageFnChange, onMessage]);

  React.useEffect(() => {
    if (!messagePort) {
      return;
    }

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", handleContextMenu);
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [messagePort]);

  return <>{props.children}</>;
};
