import Header from "./Header";
import { Box, Container } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import Footer from "./Footer";

const useStyles = makeStyles()((theme) => ({
  coordinateLayout: {
    display: "grid",
    gridTemplateColumns: "1fr 300px",
    gridTemplateRows: "1fr",
    backgroundColor: theme.palette.background.default,

    width: "100%",
    overflow: "hidden",
    height: "100vh",
  },
  sidebarWrapper: {
    padding: theme.spacing(6, 2, 2, 2),
    overflowY: "auto",
    minHeight: 100,
    height: "auto",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  contentWrapper: {
    overflowY: "auto",
  },
}));

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout(props: LayoutProps) {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <>
      <Header />
      <Box component="div" className={classes.coordinateLayout}>
        <Box component="div" className={classes.contentWrapper}>
          <Container sx={{ pt: 8 }}>{children}</Container>
          <Footer />
        </Box>
        <Box component="div" className={classes.sidebarWrapper}>
          <div id="sidebarPortal" />
        </Box>
      </Box>
    </>
  );
}
