import { z } from "zod";
import { buildUiBridgeConfig } from "./builder";
import { baseAssetSchema } from "@generate/localhub";

const clientNavigateSchema = z.object({
  messageType: z.literal("navigate"),
  projectId: z.string(),
  action: z.union([z.literal("new"), z.literal("show")]),
});
export type CoordinateNavigateMessage = z.infer<typeof clientNavigateSchema>;

const baseLocalFileDataSchema = baseAssetSchema
  .omit({ __type: true, guid: true, url: true })
  .extend({ data: z.union([z.instanceof(Blob), z.string()]), ownerId: z.string().optional() });

export const localFileDataSchema = z.discriminatedUnion("assetType", [
  baseLocalFileDataSchema.extend({ assetType: z.literal("image"), width: z.number(), height: z.number() }),
  baseLocalFileDataSchema.extend({ assetType: z.literal("other") }),
]);
export type LocalFileData = z.infer<typeof localFileDataSchema>;

export const projectComponentSnapshotSchema = z.object({
  guid: z.string(),
  name: z.string(),
  assetIds: z.array(z.string()),
  parentId: z.string().optional(),
});
export type ProjectComponentSnapshot = z.infer<typeof projectComponentSnapshotSchema>;

const clientProjectFileSchema = z.object({
  messageType: z.literal("projectFile"),
  projectData: z.string(),
  exportFiles: localFileDataSchema.array(),
});
export type ClientProjectFile = z.infer<typeof clientProjectFileSchema>;

const hostGetProjectFileSchema = z.object({
  messageType: z.literal("getProjectFile"),
  generatePdf: z.boolean(),
  generateXls: z.boolean(),
  projectId: z.string(),
});
export type HostGetProjectFileMessage = z.infer<typeof hostGetProjectFileSchema>;

export const coordinateBridgeAppStateSchema = z.object({
  authenticated: z.boolean(),
  darkMode: z.boolean(),
  buildings: z.array(projectComponentSnapshotSchema).default([]),
  keyPlans: z.array(projectComponentSnapshotSchema).default([]),
  designOptions: z.array(projectComponentSnapshotSchema).default([]),
  levelGroups: z.array(projectComponentSnapshotSchema).default([]),
});
export type CoordinateBridgeAppState = z.infer<typeof coordinateBridgeAppStateSchema>;

const coordinateSyncAppState = z.object({
  messageType: z.literal("syncAppState"),
  appState: coordinateBridgeAppStateSchema,
});

export const coordinateUiBridgeConfig = buildUiBridgeConfig({
  clientMessageSchemas: [clientNavigateSchema, coordinateSyncAppState, clientProjectFileSchema],
  hostMessageSchemas: [hostGetProjectFileSchema],
});
