import { z } from "zod";

export const baseAssetSchema = z.object({
  __type: z.literal("asset").default("asset"),
  assetType: z.string().default("other"),
  guid: z.string(),
  createdByEmail: z.string().nullish(),
  name: z.string(), // Display name
  fileName: z.string().nullish(),
  mimeType: z.string().nullish(),
  url: z.string().url({ message: "Invalid url" }),
});

export const imageAssetSchema = baseAssetSchema.extend({
  assetType: z.literal("image"),
  width: z.number(),
  height: z.number(),
});

export const gltfAssetSchema = baseAssetSchema.extend({
  assetType: z.literal("gltf"),
});

export const otherAssetSchema = baseAssetSchema.extend({
  assetType: z.literal("other"),
});

export type ImageAsset = z.infer<typeof imageAssetSchema>;
export type GltfAsset = z.infer<typeof gltfAssetSchema>;
export type OtherAsset = z.infer<typeof otherAssetSchema>;

export const assetSchema = z.discriminatedUnion("assetType", [gltfAssetSchema, imageAssetSchema, otherAssetSchema]);
export const assetTypeEnum = z.enum(["image", "gltf", "other"]);

export type Asset = z.infer<typeof assetSchema>;
