import { Box, Typography, useTheme } from "@mui/material";
import { NonUiBridgeClientContent } from "./CoordinateUiBridgeProvider";
import { useUser } from "@auth0/nextjs-auth0/client";

export default function Footer() {
  const theme = useTheme();
  const { user } = useUser();

  return (
    <NonUiBridgeClientContent>
      <>
        {user && (
          <Box
            component="div"
            sx={{
              my: 2,
              p: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
              scrollSnapAlign: "start",
            }}
          >
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} Generate, LLC. All rights reserved.
            </Typography>
          </Box>
        )}
      </>
    </NonUiBridgeClientContent>
  );
}
