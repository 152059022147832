// Allows connecting with
// - ui.generate.design (production)
// - netlify review apps for WebUI
// - anything that ends in localhost

export const isValidHostOrigin = (url: string | null | undefined, allowedHostOrigins: Array<string>): url is string => {
  if (!url) return false;
  return (
    allowedHostOrigins.some((allowedHostOrigin) => url.startsWith(allowedHostOrigin)) ||
    uiUrlRegex(allowedHostOrigins).test(url)
  );
};

export const uiUrlRegex = (allowedHostOrigins: Array<string>) => {
  return new RegExp(
    `^https?:\\/\\/(?:(?:ui\\.generate\\.design)|(?:(?:.+)?localhost(?::\\d+)?)|(?:(?:.+--)taupe-frangollo-2d7cc8\\.netlify\\.app))$|^${allowedHostOrigins.join(
      "^|",
    )}`,
  );
};
