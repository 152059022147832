import { useAnalyticsIdentify } from "@/utils/analytics";
import { useUser } from "@auth0/nextjs-auth0/client";
import { AppBar, Box, IconButton, Stack, SvgIcon, Toolbar, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { NonUiBridgeClientContent, UiBridgeClientContent } from "./CoordinateUiBridgeProvider";
import Head from "next/head";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    justifyContent: "space-between",
    height: theme.mixins.toolbar.minHeight,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Header() {
  const { user } = useUser();
  const { classes, theme } = useStyles();

  const handleBack = () => {
    window.history.back();
  };

  const handleForward = () => {
    window.history.forward();
  };

  useAnalyticsIdentify(user);

  return (
    <>
      <Head>
        <link rel="icon" href="https://generate.imgix.net/icon-black.png?w=192&h=192&bg=ffffff&mask=ellipse" />
      </Head>

      <AppBar classes={{ root: classes.root }} position="fixed" elevation={0}>
        <Toolbar disableGutters>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            flexGrow={1}
            sx={{ mx: 2 }}
          >
            <Box component="div">
              <UiBridgeClientContent>
                <Stack direction="row" spacing={0} alignItems="center">
                  <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton onClick={handleForward}>
                    <ArrowForwardIcon />
                  </IconButton>

                  <Typography variant="overline" component="div" sx={{ lineHeight: 1.5 }}>
                    Coordinate
                  </Typography>
                </Stack>
              </UiBridgeClientContent>
              <NonUiBridgeClientContent>
                <SvgIcon
                  viewBox="0 0 926.3 87.6"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    color: theme.palette.text.primary,
                    width: 175,
                    pr: 2,
                  }}
                >
                  <path d="M689 85.2L663.6 30.5 638.1 85.2 623.6 85.2 663.6 -0.4 703.5 85.2z" />
                  <path d="M396.1 34.1L443.6 34.1 443.6 47.2 409.2 47.2 409.2 72.1 443.6 72.1 443.6 85.2 396.1 85.2z" />
                  <path d="M396.1 2.4H443.6V15.5H396.1z" />
                  <path d="M784.2 85.2L784.2 15.4 765.6 15.4 765.6 2.4 816.6 2.4 816.6 15.4 798.1 15.4 798.1 85.2" />
                  <path d="M532.1 39.3c6.6 0 11.9-5.3 11.9-11.9 0-6.6-5.3-11.9-11.9-11.9h-26.3V2.4h26.3c12.7 0 23.3 9.5 24.8 22 1.5 12.6-6.6 24.3-18.9 27.3 7.8 11.2 15.7 22.4 23.5 33.6h-16c-8.9-12.7-17.8-25.4-26.6-38.1v38.1h-13.1v-46h26.3z" />
                  <path d="M259.5 85.2L272.6 85.2 272.6 29 333.9 91.4 333.9 2.4 320.8 2.4 320.8 59.4 259.5 -3z" />
                  <path d="M149.8 72.1H197.3V85.19999999999999H149.8z" />
                  <path d="M39.2 37.6H86.7V50.7H39.2z" />
                  <path d="M891.8 47.2L891.8 72.1 926.3 72.1 926.3 85.2 878.8 85.2 878.8 2.4 926.3 2.4 926.3 15.4 891.8 15.4 891.8 34.1 926.3 34.1 926.3 47.2z" />
                  <path d="M149.8 2.4H197.3V15.5H149.8z" />
                  <path d="M259.3 15.4L259.5 15.6" />
                  <path d="M149.8 37.6H197.3V50.7H149.8z" />
                  <path d="M67.4 63.8c-5.7 6.6-14.1 10.9-23.5 10.9C26.8 74.7 13 60.8 13 43.8s13.8-30.9 30.9-30.9c9.8 0 18.5 4.6 24.2 11.7h15.1C76 10 61.1 0 43.8 0 19.6 0 0 19.6 0 43.8 0 68 19.6 87.6 43.8 87.6c17 0 31.7-9.7 39-23.8H67.4z" />
                </SvgIcon>
              </NonUiBridgeClientContent>
            </Box>
            <Box component="div" id="headerPortal" sx={{ alignSelf: "right", flexGrow: 1, mx: 2 }} />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}
