export const newSnapshotPath = (projectGuid: string) => {
  return `/snapshots/${projectGuid}/new`;
};

export const noSnapshotsPath = (projectGuid: string) => {
  return `/snapshots/${projectGuid}/none`;
};

export const latestSnapshotPath = (projectGuid: string) => {
  return `/snapshots/${projectGuid}`;
};

export const snapshotPath = (projectGuid: string, createdAt: string) => {
  const date = new Date(createdAt);
  return `/snapshots/${projectGuid}?createdAt=${date.getTime()}`;
};

export const projectPath = (projectGuid: string) => {
  return `/projects/${projectGuid}`;
};

export const latestBuildingPath = (projectGuid: string, buildingGuid: string) => {
  return `/buildings/${compoundSlug(projectGuid, buildingGuid)}`;
};

export const buildingPath = (projectGuid: string, buildingGuid: string, createdAt: string) => {
  const date = new Date(createdAt);
  return `/buildings/${compoundSlug(projectGuid, buildingGuid)}?createdAt=${date.getTime()}`;
};

export const noBuildingSnapshotsPath = (compoundGuid: string) => {
  return `/buildings/${compoundGuid}/none`;
};

// NOTE: The compoundGuid is a concatenation of the projectGuid and the buildingGuid
// This is not a secure way to generate a slug, but it is good enough for our purposes
// It's just some security through obscurity

export const compoundSlug = (projectGuid: string, buildingGuid: string) => {
  return `${projectGuid}${buildingSlug(buildingGuid)}`;
};

export const buildingSlug = (buildingGuid: string) => {
  return buildingGuid.substring(0, 6);
};

export const parseCompoundGuid = (compoundGuid: string) => {
  const projectGuid = compoundGuid.substring(0, 32);
  const buildingSlug = compoundGuid.substring(32);

  return { projectGuid, buildingSlug };
};
