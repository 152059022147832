import * as React from "react";
import { isValidHostOrigin } from "../hostOrigin";

const buildMessageChannel = () => new MessageChannel();

export const useHostMessagePort = (frameRef: React.RefObject<HTMLIFrameElement>, origin: string): MessagePort => {
  const [messageChannel, setMessageChannel] = React.useState(buildMessageChannel);

  React.useEffect(() => {
    const registerClientMessageHandler = (event: MessageEvent) => {
      if (event.origin === origin && event.data === "uiBridgeRegisterClient") {
        try {
          frameRef.current?.contentWindow?.postMessage("uiBridgeRegisterHost", origin, [messageChannel.port2]);
        } catch (e) {
          if (e instanceof DOMException && e.name === "DataCloneError") {
            // If we couldn't send this message, it was likely because the message port was sent to a previous
            // instance of the page. Create a new channel and try again.
            const newChannel = buildMessageChannel();
            setMessageChannel(newChannel);
            frameRef.current?.contentWindow?.postMessage("uiBridgeRegisterHost", origin, [newChannel.port2]);
          } else {
            throw e;
          }
        }
      }
    };

    window.addEventListener("message", registerClientMessageHandler);

    return () => {
      window.removeEventListener("message", registerClientMessageHandler);
    };
  }, [frameRef, messageChannel.port2, origin]);

  return messageChannel.port1;
};

export const useClientMessagePort = (allowedHostOrigins: Array<string>): MessagePort | null => {
  // Only send the register event once. If we send a second event, we'll get a new message port and may lose events
  // that were queued on the host while this was initializing.
  const registerEventSent = React.useRef(false);
  const [messagePort, setMessagePort] = React.useState<MessagePort | null>(null);

  React.useEffect(() => {
    const registerHostMessageHandler = (event: MessageEvent) => {
      if (isValidHostOrigin(event.origin, allowedHostOrigins) && event.data === "uiBridgeRegisterHost") {
        const receivedPort = event.ports[0];
        if (receivedPort) {
          setMessagePort(receivedPort);
        }
      }
    };

    window.addEventListener("message", registerHostMessageHandler);

    if (!registerEventSent.current) {
      registerEventSent.current = true;
      window.parent.postMessage("uiBridgeRegisterClient", "*");
    }

    return () => {
      window.removeEventListener("message", registerHostMessageHandler);
    };
  }, [allowedHostOrigins]);

  return messagePort;
};
