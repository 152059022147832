import "@/styles/globals.css";
import "typeface-lato";

import React from "react";
import { CacheProvider, EmotionCache } from "@emotion/react";
import type { AppProps } from "next/app";
import { GenThemeProvider } from "@/components/GenThemeProvider";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { createEmotionCache } from "@/styles/createEmotionCache";
import Layout from "@/components/Layout";
import { CoordinateUiBridgeClientProvider } from "@/components/CoordinateUiBridgeProvider";
import { GenCoreConfig, GenCoreConfigProvider } from "@generate/core";
import Link from "@/components/Link";

const clientSideEmotionCache = createEmotionCache();

const genCoreConfig: GenCoreConfig = {
  baseSupplyLinkUrl: process.env.NEXT_PUBLIC_GENERATE_SUPPLY_CANONICAL_URL ?? "",
  LinkComponent: Link,
};
interface AppWithEmotionCacheProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function App(props: AppWithEmotionCacheProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const pagePropsRef = React.useRef(pageProps);

  React.useEffect(() => {
    pagePropsRef.current = pageProps;
  }, [pageProps]);

  React.useEffect(() => {
    if (!window || !window.analytics) return;
    window.analytics.page();
  }, []);

  if (pageProps.skipLayout) return <Component {...pageProps} />;

  return (
    <CacheProvider value={emotionCache}>
      <CoordinateUiBridgeClientProvider>
        <GenCoreConfigProvider config={genCoreConfig}>
          <GenThemeProvider>
            <UserProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </UserProvider>
          </GenThemeProvider>
        </GenCoreConfigProvider>
      </CoordinateUiBridgeClientProvider>
    </CacheProvider>
  );
}
