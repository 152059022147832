import React from "react";
import * as Sentry from "@sentry/nextjs";
import { UserProfile } from "@auth0/nextjs-auth0/client";

const logDevelopmentAnalyticsCall =
  (method: string | symbol) =>
  (...args: unknown[]) => {
    // eslint-disable-next-line no-console
    console.log(`ANALYTICS - "${method.toString()}" called with`, ...args);
  };

const handleServerAnalyticsCall =
  (method: string | symbol) =>
  (...args: unknown[]) => {
    const methodStr = method.toString();
    Sentry.captureMessage(`Analytics ${methodStr} call skipped due to being called on the server`, {
      level: "warning",
      extra: { args: args },
    });
  };

export const analytics = new Proxy<SegmentAnalytics.AnalyticsJS>({} as SegmentAnalytics.AnalyticsJS, {
  get: (_target, prop) => {
    if (process.env.NODE_ENV === "development") {
      return logDevelopmentAnalyticsCall(prop);
    }

    if (window?.analytics) {
      // @ts-ignore
      return window.analytics[prop];
    }

    return handleServerAnalyticsCall(prop);
  },
});

export const useAnalyticsTrack = (event: string, properties?: Object | undefined) => {
  const shallowProps = properties ? Object.values(properties) : [];

  React.useEffect(() => {
    analytics.track(event, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...shallowProps]);
};

export const useAnalyticsIdentify = (user: UserProfile | undefined) => {
  React.useEffect(() => {
    if (user) {
      const userId = user.sub || "";
      analytics.identify(userId, {
        name: user.name,
        email: user.email,
      });
    } else {
      analytics.reset();
    }
  }, [user]);
};

export const trackDownload = (filename: string) => {
  analytics.track("File Downloaded", { filename });
};
