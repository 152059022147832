import { CssBaseline, ThemeProvider } from "@mui/material";
import { genTheme } from "@generate/gen-blocks";
import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { useUiBridgeClient } from "./CoordinateUiBridgeProvider";

export const headerFont = ["futura-pt", "sans-serif"].join(",");

export const GenThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const uiBridgeClient = useUiBridgeClient();
  const darkMode = uiBridgeClient?.appState?.darkMode ?? false;

  const theme = createTheme(
    deepmerge(genTheme(darkMode, headerFont), {
      components: {},
    }),
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div data-theme={darkMode ? "dark" : "light"}>{children}</div>
    </ThemeProvider>
  );
};
