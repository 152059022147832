import { ThemeOptions } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

// https://coolors.co/e3b692-89cc7f-477e38-303030-212121
// Buff: #E3B692
// Pastachio: #89CC7F
// Fern Green: #477E38
// Jet: #303030
// Eerie Black: #212121

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    neutral: {};
  }
}

export const genTheme = (darkMode: boolean, headerFont: string): ThemeOptions => {
  return {
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      fontSize: 12,
      htmlFontSize: 12,
      h1: { fontSize: "3rem", fontWeight: 700, fontFamily: headerFont },
      h2: { fontSize: "2rem", fontWeight: 700, fontFamily: headerFont },
      h3: { fontSize: "1.5rem", fontWeight: 700, fontFamily: headerFont },
      h4: { fontSize: "1.5rem", fontWeight: 700, fontFamily: headerFont },
      h5: { fontSize: "1.4rem", fontWeight: 700, fontFamily: headerFont },
      h6: { fontSize: "1rem", fontWeight: 700, fontFamily: headerFont },
      subtitle1: { fontSize: "1.3rem", fontFamily: headerFont },
      overline: { fontSize: "1.1rem", fontFamily: headerFont },
      body1: { fontSize: "1rem" },
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: grey,
      secondary: {
        main: "#477E38",
      },
      neutral: {
        main: darkMode ? "#fafafa" : "#212121",
        contrastText: darkMode ? "#212121" : "#fafafa",
      },
      background: {
        default: darkMode ? "#303030" : "#fafafa",
        paper: darkMode ? grey["800"] : "#fff",
      },
    },
    mixins: {
      toolbar: {
        minHeight: 36,
      },
    },
    components: {
      MuiAvatar: {
        defaultProps: {
          sx: {
            width: 24,
            height: 24,
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "xl",
          style: {
            // backgroundColor: "rgba(255, 0, 0, 0.2)",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: "neutral",
          disableElevation: true,
        },
      },
      MuiLink: {
        defaultProps: {
          color: darkMode ? grey[400] : grey[600],
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
  };
};
