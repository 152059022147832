import * as React from "react";
import { useRouter } from "next/router";
import { isEqual } from "lodash";
import {
  CoordinateBridgeAppState,
  UiBridgeClient,
  UiBridgeClientSendMessageFn,
  UiBridgeClientMessageCallback,
  coordinateUiBridgeConfig,
  UiBridgeClientMessage,
} from "@generate/ui-bridge";
import { newSnapshotPath, latestSnapshotPath } from "@/utils/links";
import { Backdrop } from "@mui/material";
import { buildMessageHub } from "@generate/core";
import { GenCircularProgress } from "@generate/gen-blocks";

const subscribeMessageHub = buildMessageHub<UiBridgeClientMessage<UiBridgeConfig>>();

type UiBridgeConfig = typeof coordinateUiBridgeConfig;

export type UiBridgeClient = {
  dispatch: UiBridgeClientSendMessageFn<UiBridgeConfig>;
  subscribe: (typeof subscribeMessageHub)["subscribe"];
  appState: CoordinateBridgeAppState;
};

const UiBridgeClientContext = React.createContext<UiBridgeClient | null>(null);

export const useUiBridgeClient = () => React.useContext(UiBridgeClientContext);

export type UiBridgeClientContentProps = {
  children: ((client: UiBridgeClient) => React.ReactElement) | React.ReactElement;
};

export const UiBridgeClientContent = (props: UiBridgeClientContentProps) => {
  const { children } = props;
  const client = useUiBridgeClient();

  if (client) {
    if (typeof children === "function") {
      return <>{children(client)}</>;
    } else {
      return <>{children}</>;
    }
  } else {
    return null;
  }
};

export const NonUiBridgeClientContent = (props: UiBridgeClientContentProps) => {
  const { children } = props;
  const client = useUiBridgeClient();

  if (!client) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export const CoordinateUiBridgeClientProvider: React.FC<React.PropsWithChildren> = (props) => {
  const router = useRouter();

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [appState, setAppState] = React.useState<CoordinateBridgeAppState | null>(null);
  const [dispatchFn, setDispatchFn] = React.useState<UiBridgeClientSendMessageFn<UiBridgeConfig> | null>(null);

  const handleMessage = React.useCallback<UiBridgeClientMessageCallback<UiBridgeConfig>>(
    (message) => {
      subscribeMessageHub.publish(message);

      switch (message.messageType) {
        case "syncAppState":
          {
            setAppState(message.appState);
          }
          break;
        case "navigate":
          {
            const { projectId, action } = message;

            let newRoute;
            switch (action) {
              case "show":
                newRoute = newSnapshotPath(projectId);
                break;
              default:
                newRoute = latestSnapshotPath(projectId);
                break;
            }

            const currentRoute = { pathname: router.pathname, query: router.query };

            if (!isEqual(currentRoute, newRoute)) {
              setBackdropOpen(true);
              void router.push(newRoute);
            }
          }
          break;
      }
    },
    [router],
  );

  React.useEffect(() => {
    const callback = () => {
      setBackdropOpen(false);
    };
    router.events.on("routeChangeComplete", callback);
    return () => router.events.off("routeChangeComplete", callback);
  }, [router.events]);

  const handleDispatchFnChange = React.useCallback<
    (dispatchFn: UiBridgeClientSendMessageFn<UiBridgeConfig> | null) => void
  >((dispatchFn) => {
    setDispatchFn(() => {
      return dispatchFn;
    });
  }, []);

  const client: UiBridgeClient | null =
    dispatchFn && appState ? { dispatch: dispatchFn, subscribe: subscribeMessageHub.subscribe, appState } : null;

  return (
    <UiBridgeClient
      config={coordinateUiBridgeConfig}
      allowedHostOrigins={
        process.env.NEXT_PUBLIC_ALLOWED_UI_BRIDGE_HOST ? [process.env.NEXT_PUBLIC_ALLOWED_UI_BRIDGE_HOST] : []
      }
      onMessage={handleMessage}
      onSendMessageFnChange={handleDispatchFnChange}
    >
      <UiBridgeClientContext.Provider value={client}>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropOpen}>
          {backdropOpen && <GenCircularProgress color="inherit" />}
        </Backdrop>
        {props.children}
      </UiBridgeClientContext.Provider>
    </UiBridgeClient>
  );
};
