export type MessageHubChannelUnsubscribeFn = () => void;

type MessageHub<Message extends { messageType: string }> = {
  publish: (message: Message) => void;

  subscribe: <MessageType extends Message["messageType"]>(
    eventName: MessageType,
    callback: (message: Extract<Message, { messageType: MessageType }>) => void,
  ) => MessageHubChannelUnsubscribeFn;
};

export const buildMessageHub = <Message extends { messageType: string }>(): MessageHub<Message> => {
  type MessageType = Message["messageType"];
  type Hub = MessageHub<Message>;

  type SubcribersMap = {
    [eventName in MessageType]?: Array<(message: Extract<Message, { messageType: eventName }>) => void>;
  };
  const subscribers: SubcribersMap = {};

  const subscribe: Hub["subscribe"] = (eventName, callback) => {
    subscribers[eventName] ||= [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    subscribers[eventName]!.push(callback);

    return () => {
      const eventSubscribers = subscribers[eventName];
      if (eventSubscribers) {
        eventSubscribers.splice(eventSubscribers.indexOf(callback), 1);
      }
    };
  };

  const publish = (message: Message) => {
    // @ts-expect-error
    const eventSubscribers = subscribers[message.messageType];
    if (eventSubscribers) {
      // @ts-expect-error
      eventSubscribers.forEach((callback) => {
        callback(message);
      });
    }
  };

  return {
    subscribe,
    publish,
  };
};
