import { ZodDiscriminatedUnionOption, z } from "zod";

type MessageSchemas = [ZodDiscriminatedUnionOption<"messageType">, ...ZodDiscriminatedUnionOption<"messageType">[]];

// This describes the messages that can be sent TO each side -
//   - clientMessage = messages accepted by the client
//   - hostMessage = messages accepted by the host
export const buildUiBridgeConfig = <ClientSchemas extends MessageSchemas, HostSchemas extends MessageSchemas>(params: {
  clientMessageSchemas: ClientSchemas;
  hostMessageSchemas: HostSchemas;
}) => {
  const clientMessageSchema = z.discriminatedUnion("messageType", params.clientMessageSchemas);
  const hostMessageSchema = z.discriminatedUnion("messageType", params.hostMessageSchemas);

  return {
    clientMessageSchema,
    hostMessageSchema,
  };
};

export type UiBridgeConfig = ReturnType<typeof buildUiBridgeConfig>;
export type UiBridgeClientMessage<T extends UiBridgeConfig> = z.infer<T["clientMessageSchema"]>;
export type UiBridgeHostMessage<T extends UiBridgeConfig> = z.infer<T["hostMessageSchema"]>;

export type UiBridgeMessage = z.ZodDiscriminatedUnion<"messageType", MessageSchemas>;
