import { Link } from "@mui/material";
import * as React from "react";

type FullGenCoreConfig = {
  baseSupplyLinkUrl: string;
  LinkComponent: React.ElementType;
};

export type GenCoreConfig = Partial<FullGenCoreConfig>;

export const deafaultGenCoreConfig: FullGenCoreConfig = {
  baseSupplyLinkUrl: "",
  LinkComponent: Link,
};

const GenCoreConfigContext = React.createContext<FullGenCoreConfig>(deafaultGenCoreConfig);

export const useGenCoreConfig = () => React.useContext(GenCoreConfigContext);

type Props = React.PropsWithChildren<{
  config: GenCoreConfig;
}>;
export const GenCoreConfigProvider: React.FC<Props> = (props) => {
  const { config, ...rest } = props;
  return <GenCoreConfigContext.Provider {...rest} value={{ ...deafaultGenCoreConfig, ...config }} />;
};
